.header-box {
    height: 96px;
    background-size: cover;
    background-image: url(../../assets/9.png);
    .bg-with-text {
      position: relative;
      .h2 {
        position: absolute;
        color: #fff;
        right: 264px;
        font-size: 58px;
        margin: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
    .cm-logo {
      height: 104px;
      background-repeat: no-repeat;
      background-image: url(../../assets/11.png);
      width: 224px;
      /* border-bottom-left-radius: 21px; */
      background-size: cover;
      .img {
        /* width: 160px;
        margin: 36px; */
        float: right;
        width: 176px;
        margin: 20px 9px 25px 18px;
      }
    }
  }
  