.time-and-weather {
    height: 160px;
    background-size: contain;
    background-repeat: repeat-x;
    padding: 8px;
    background-color: #00a4e0;
    /* background-image: linear-gradient(141deg, #0055a3 0%, #00a4e0 51%, #00bef4 75%); */
    background-image: linear-gradient(#00ccff 0%, #004c9c 75%);
    .time {
      color: #fff;
      display: block;
      font-size: 89px;
      font-family: "Almoni", sans-serif;
      line-height: 0.8;
      text-align: right;
      /* padding-right: 40px; */
    }
    .date {
      color: #fff;
      font-size: 18px;
      border-bottom: 2px solid #ffffff8c;
      margin-bottom: 4px;
      padding-bottom: 8px;
      text-align: right;
      /* padding-right: 40px; */
    }
    .weather {
      text-align: center;
      display: flex;
      flex-direction: row-reverse;
      color: #fff;
      justify-content: center;
      /* padding-right: 10px; */
      .weather-icon {
        width: 30px;
        margin-left: 8px;
        margin-right: 8px;
        img {
          width: 100%;
        }
      }
      .weather-temp {
        font-size: 23px;
      }
      .time-and-weather {
        padding: 10px;
      }
      .text {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
  
  @media (max-width: 1890px) {
    .time-and-weather .weather .weather-temp {
      font-size: 23px;
    }
    .time-and-weather .weather .weather-icon {
      width: 30px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  