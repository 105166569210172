.media-slides {
  height: 100%;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
}

.media-swiper-2 {
  background-color: white;
  width: 100%;
  height: 100%;
}

.media-swiper-1 {
  width: 100%;
  height: 100%;
  /* top: -44px; */
}

