.header-container-wrapper {
  height: 172px;
}

.body-container-wrapper {
  height: calc(100vh - 248px);
  padding-left: 8px;
  padding-right: 16px;

  .custom-gap {
    padding: 0px 8px !important;
  }

  .move-up {
    margin-top: -56px;
    height: calc(100vh - 196px);
    display: flex;
    flex-direction: column;
  }

  .msg-section-move-up {
    position: relative;
    top: -56px;
    height: 44px;
    background-size: cover;
    background-color: #fff;
    width: 114%;
  }
}

.footer-container-wrapper {
  height: 60px;
  background: #001A33;
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
}

.msg-section-move-up:before {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid #e6edf5;
  border-bottom: 20px solid transparent;
  position: absolute;
  content: "";
  right: 0;
  top: 2px;
}

.msg-section-move-up:after {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid #e6edf5;
  border-bottom: 20px solid transparent;
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  transform: rotate(180deg);
}

.sidebar-image-hidden {
  display: block;
  /* padding-top: 43px;
     padding-bottom: 43px; */
}

.right-sidebar-horz-slider {
  flex: 0 0 300px;
  max-height: 300px;
  overflow: hidden;
}

.right-sidebar-chat-box {
  /* flex: 0 0 calc(100vh - 450px);
     max-height: calc(100vh - 450px);
     margin-top: 43px; */
  flex: 0 0 calc(100% - 300px);
  max-height: calc(100% - 300px);
  padding-top: 43px;
}

.col-15 {
  flex: 0 0 12.5% !important;
  max-width: 12.5% !important;
}

.col-25 {
  flex: 0 0 20.8333333333% !important;
  max-width: 20.8333333333% !important;
}

.col-35 {
  flex: 0 0 29.1666666667% !important;
  max-width: 29.1666666667% !important;
}

.col-45 {
  flex: 0 0 37.5% !important;
  max-width: 37.5% !important;
}

.col-55 {
  flex: 0 0 45.8333333333% !important;
  max-width: 45.8333333333% !important;
}

.col-65 {
  flex: 0 0 54.1666666667% !important;
  max-width: 54.1666666667% !important;
}

.col-75 {
  flex: 0 0 62.5% !important;
  max-width: 62.5% !important;
}

.col-85 {
  flex: 0 0 70.8333333333% !important;
  max-width: 70.8333333333% !important;
}

.col-95 {
  flex: 0 0 79.1666666667% !important;
  max-width: 79.1666666667% !important;
}

.center-media-container {
  width: 100%;
  padding: 15px;
  background-color: white;
  top: -44px;
  position: relative;
  height: calc(100vh - 252px);
  /* max-height: 100%; */
  
}