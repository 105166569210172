.left-sidebar-wrapper {
    height: calc(100vh - 252px) !important;
    display: flex;
    flex-direction: column;
   

    .market {
        flex: 0 0 40px;
        max-height: 40px;
    }

    .bulletin {
        /* flex: 0 0 calc(100vh - 580px); */
        /* max-height: calc(100vh - 580px) !important; */
        height: 100%;
        overflow: hidden;
        background-color: white;
        padding: 10px;
    }

    .card.card-3 {
        flex: 0 0 160px;
        max-height: 160px;
        border-radius: 0;
        margin-bottom: 0;
        border: 0;
    }
    .swiper-slides {
        height: auto !important;

    }
}

.news-block_MarketRss-hidden {
    flex: 0 0 calc(100vh - 422px) !important;
    max-height: calc(100vh - 422px) !important;
}

.news-block_CardBoxWithHeading-hidden {
    flex: 0 0 calc(100vh - 294px) !important;
    max-height: calc(100vh - 294px) !important;
}

.news-block_MarketRss-hidden_CardBoxWithHeading-hidden {
    /* flex: 0 0 calc(100vh - 255px) !important; */
    max-height: calc(100vh - 255px) !important;
}

.sidebar-news-block {
    .text-area {
        padding-right: 5px;

        .headline {
            font-size: 18px;
            line-height: 1.4;
            font-weight: 600;
            height: 50px;
            overflow: hidden;
        }

        .subtitle {
            font-size: 12px;
        }
    }

    .pic-area {
        width: 110px;
        float: right;
        margin-left: 10px;

        img {
            width: 100%;
        }
    }

}


/* Candel */

.card-heading h3 {
    font-size: 37px;
    margin: 0;
  }
  .card-content {
    .card-right-side {
      background: #fff9e2;
      position: relative;
      right: 10px;
      text-align: center;
      margin-bottom: -5px;
      height: 112px;
      .shabbat-img {
        width: 82%;
        margin-top: -35px;
        padding: 0 10px;
      }
    }
    .card-left-side .shabbat-ul {
      list-style: none;
      padding: 18px 12px 18px 0;
      text-align: right;
      margin-bottom: 0;
      white-space: nowrap;
      .shabbat-li {
        color: #004c9c;
        font-weight: 400;
        font-size: 30px;
      }
    }
    .time {
      margin-right: 10px;
    }
  }
  
  .card-right-side {
    flex: 0 0 120px;
    max-width: 120px;
    margin-bottom: 0 !important;
  }
  
  .card-left-side {
    flex: 0 0 calc(100% - 120px);
    max-width: calc(100% - 120px);
  }
  
  .card-3-area {
    height: 100%;
  }
  
  .card-content.row.no-gutters {
    height: 100%;
  }
  
  .card-right-side img {
    width: 87% !important;
    margin-bottom: -45px;
  }
  
  @media (max-width: 1600px) {
    .card-heading h3 {
      font-size: 27px;
    }
    .card-content .card-left-side ul li {
       font-size: 24px;
      span {
        margin-left: 20px;
      }
    }
  }
  