:host {
  display: block;
}

.news-lines {
  position: relative;
}

.logo-area {
  position: absolute;
  right: 0;
  top: -20px;
  width: 80px;
  height: 80px;

  .img {
    width: 100%;
  }
}

.slider-area {
  width: calc(100% - 80px);
  float: left;
  height: 60px;
  padding: 15px;
  color: #fff;
  overflow: hidden;

  .ul {
    padding: 0;
    margin: 0;
    white-space: nowrap;

    .lines {
      display: inline-block;
      color: #fff;
      font-size: 23px;
    }

    .times {
      color: #D7263D;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 23px;
    }
  }
}

marquee {
  direction: rtl;
}