.msg-block {
    color: #004c9c;
    text-align: right;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    height: 100%;

    .header-msgs {
        height: 100%;
    }
}

:host {
    display: block;
}

.inliine-content {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #004c9c;
}